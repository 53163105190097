import { createElement, isNullOrUndefined } from '@syncfusion/ej2-base';
import { stringToNumber, measureText, getLocationFromAngle, getLabelFormat, VisibleLabels, isCompleteAngle, Size, Rect } from '../utils/helper-common';
import { calculateSum } from '../utils/helper-axis-panel';
import { axisLabelRender, radiusCalculate } from '../model/constants';
import { AxisRenderer } from './axis-renderer';
import { PointerRenderer } from './pointer-renderer';
/**
 * Specifies the CircularGauge Axis Layout
 */
var AxisLayoutPanel = /** @class */ (function () {
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    function AxisLayoutPanel(gauge) {
        this.gauge = gauge;
        this.axisRenderer = new AxisRenderer(gauge);
        this.pointerRenderer = new PointerRenderer(gauge);
    }
    /**
     * Measure the calculate the axis size and radius.
     *
     * @return {void}
     * @private
     */
    AxisLayoutPanel.prototype.measureAxis = function (rect) {
        this.measureAxisSize(this.gauge, rect);
        this.calculateAxesRadius();
    };
    /**
     * Measure to calculate the axis radius of the circular gauge.
     *
     * @returns {void}
     * @private
     */
    AxisLayoutPanel.prototype.calculateAxesRadius = function () {
        var _this = this;
        var totalRadius;
        var currentRadius;
        var rangeMaximumRadius = 0;
        var xMarginDiff = this.gauge.margin.left + this.gauge.margin.right;
        var yMarginDiff = this.gauge.margin.top + this.gauge.margin.bottom;
        var _loop_1 = function (axis) {
            totalRadius = (Math.min(axis.rect.width, axis.rect.height) / 2);
            currentRadius = axis.radius != null ? stringToNumber(axis.radius, totalRadius) : totalRadius;
            // eslint-disable-next-line prefer-spread
            rangeMaximumRadius = Math.max.apply(Math, axis.ranges.map(function (value) {
                return value.radius ?
                    (value.radius.indexOf('%') < 0 ? 100 : parseInt(value.radius, 10)) : 0;
            }));
            currentRadius = (rangeMaximumRadius > 100 && axis.radius == null) ?
                (currentRadius * 100) / rangeMaximumRadius : currentRadius;
            axis.currentRadius = currentRadius - axis.nearSize;
            if (this_1.gauge.moveToCenter && this_1.gauge.axes.length === 1 &&
                isNullOrUndefined(this_1.gauge.centerXpoint) && isNullOrUndefined(this_1.gauge.centerYpoint)) {
                var endAngle = void 0;
                var startAngle = axis.startAngle;
                var startPoint = getLocationFromAngle(startAngle - 90, currentRadius, this_1.gauge.midPoint);
                endAngle = axis.endAngle;
                endAngle -= isCompleteAngle(startAngle, endAngle) ? 0.0001 : 0;
                var endPoint = getLocationFromAngle(endAngle - 90, currentRadius, this_1.gauge.midPoint);
                var xDiff = void 0;
                var yDiff = void 0;
                var startXDiff = void 0;
                var endXDiff = void 0;
                var startYDiff = void 0;
                var endYDiff = void 0;
                var newPoint = void 0;
                if (startAngle > endAngle ? Math.abs(startAngle - endAngle) > 90 ? true : false : true) {
                    if ((startAngle >= 270 && startAngle <= 360) && ((endAngle > 270 && endAngle <= 360) ||
                        (endAngle >= 0 && endAngle <= 180))) {
                        startXDiff = Math.abs(this_1.gauge.gaugeRect.x - Math.abs(startPoint.x - this_1.gauge.gaugeRect.x));
                        newPoint = (endAngle <= 360 && endAngle >= 270) ? this_1.gauge.midPoint : (endAngle <= 90) ? endPoint :
                            getLocationFromAngle(90 - 90, currentRadius, this_1.gauge.midPoint);
                        endXDiff = Math.abs(newPoint.x - this_1.gauge.gaugeRect.width);
                        startPoint = (endAngle <= 360 && endAngle >= 270) ? endPoint :
                            getLocationFromAngle(360 - 90, currentRadius, this_1.gauge.midPoint);
                        startYDiff = Math.abs(startPoint.y - this_1.gauge.gaugeRect.y);
                        endPoint = (endAngle <= 360 && endAngle >= 270 || (endAngle >= 0 && endAngle < 90)) ?
                            this_1.gauge.midPoint : (endAngle >= 90 && endAngle <= 180) ? endPoint :
                            getLocationFromAngle(180 - 90, currentRadius, this_1.gauge.midPoint);
                        endYDiff = Math.abs(endPoint.y - (this_1.gauge.gaugeRect.y + this_1.gauge.gaugeRect.height));
                    }
                    else if ((startAngle >= 0 && startAngle < 90) && (endAngle >= 0 && endAngle <= 270)) {
                        startYDiff = Math.abs(startPoint.y - this_1.gauge.gaugeRect.y);
                        newPoint = (endAngle >= 180) ? getLocationFromAngle(180 - 90, currentRadius, this_1.gauge.midPoint) :
                            endPoint;
                        endYDiff = Math.abs(newPoint.y - (this_1.gauge.gaugeRect.y + this_1.gauge.gaugeRect.height));
                        startPoint = (endAngle >= 180) ? endPoint : this_1.gauge.midPoint;
                        startXDiff = Math.abs(this_1.gauge.gaugeRect.x - Math.abs(startPoint.x - this_1.gauge.gaugeRect.x));
                        endPoint = (endAngle >= 90) ? getLocationFromAngle(90 - 90, currentRadius, this_1.gauge.midPoint) : endPoint;
                        endXDiff = Math.abs(endPoint.x - this_1.gauge.gaugeRect.width);
                    }
                    else if ((startAngle >= 90 && startAngle < 180) && (endAngle > 90 && endAngle <= 360)) {
                        newPoint = (endAngle <= 180) ? this_1.gauge.midPoint : (endAngle >= 270) ?
                            getLocationFromAngle(270 - 90, currentRadius, this_1.gauge.midPoint) : endPoint;
                        startXDiff = Math.abs(newPoint.x - this_1.gauge.gaugeRect.x);
                        endXDiff = Math.abs(startPoint.x - this_1.gauge.gaugeRect.width);
                        startPoint = (endAngle > 270) ? getLocationFromAngle(endAngle - 90, currentRadius, this_1.gauge.midPoint) :
                            this_1.gauge.midPoint;
                        startYDiff = Math.abs(this_1.gauge.gaugeRect.y - startPoint.y);
                        endPoint = (endAngle >= 180) ? getLocationFromAngle(180 - 90, currentRadius, this_1.gauge.midPoint) : endPoint;
                        endYDiff = Math.abs(endPoint.y - (this_1.gauge.gaugeRect.y + this_1.gauge.gaugeRect.height));
                    }
                    else if ((startAngle >= 180 && startAngle <= 270) && ((endAngle <= 360 && endAngle >= 270) ||
                        (endAngle <= 180 && endAngle >= 0))) {
                        newPoint = (endAngle > 180 && endAngle < 270) ? endPoint :
                            getLocationFromAngle(270 - 90, currentRadius, this_1.gauge.midPoint);
                        startXDiff = Math.abs(this_1.gauge.gaugeRect.x - Math.abs(newPoint.x - this_1.gauge.gaugeRect.x));
                        newPoint = (endAngle >= 180 && endAngle <= 360) ? this_1.gauge.midPoint : (endAngle <= 90) ? endPoint :
                            getLocationFromAngle(0, currentRadius, this_1.gauge.midPoint);
                        endXDiff = Math.abs(newPoint.x - this_1.gauge.gaugeRect.width);
                        newPoint = (endAngle > 180 && endAngle < 270) ? this_1.gauge.midPoint : (endAngle >= 270 && endAngle <= 360) ?
                            endPoint : getLocationFromAngle(360 - 90, currentRadius, this_1.gauge.midPoint);
                        startYDiff = Math.abs(newPoint.y - this_1.gauge.gaugeRect.y);
                        endPoint = (endAngle <= 360 && endAngle >= 270 || (endAngle >= 0 && endAngle < 90)) ?
                            startPoint : ((270 - startAngle) < (endAngle - 90)) ? endPoint : startPoint;
                        endYDiff = Math.abs(endPoint.y - (this_1.gauge.gaugeRect.y + this_1.gauge.gaugeRect.height));
                    }
                    if ((!isNullOrUndefined(startXDiff) && !isNullOrUndefined(endXDiff) && !isNullOrUndefined(startYDiff) &&
                        !isNullOrUndefined(endYDiff)) && ((startXDiff > 0 || endXDiff > 0) && (startYDiff > 0 || endYDiff > 0))) {
                        xDiff = Math.abs((startXDiff + endXDiff) - xMarginDiff);
                        yDiff = Math.abs((startYDiff + endYDiff) - yMarginDiff);
                        this_1.gauge.midPoint.x = this_1.gauge.midPoint.x - (startXDiff / 2) + (endXDiff / 2);
                        this_1.gauge.midPoint.y = this_1.gauge.midPoint.y - (startYDiff / 2) + (endYDiff / 2);
                        totalRadius = (Math.min(this_1.gauge.gaugeRect.width, this_1.gauge.gaugeRect.height) / 2) +
                            (Math.min(xDiff, yDiff) / 2);
                        axis.currentRadius = (axis.radius != null ? stringToNumber(axis.radius, totalRadius) : totalRadius) - axis.nearSize;
                    }
                }
            }
            axis.visibleRange.interval = this_1.calculateNumericInterval(axis, axis.rect);
            var args = {
                cancel: false, name: radiusCalculate, currentRadius: axis.currentRadius, gauge: this_1.gauge,
                midPoint: this_1.gauge.midPoint, axis: axis
            };
            this_1.gauge.trigger('radiusCalculate', args, function () {
                axis.currentRadius = args.currentRadius;
                _this.gauge.midPoint = args.midPoint;
                _this.calculateVisibleLabels(axis);
            });
        };
        var this_1 = this;
        for (var _i = 0, _a = this.gauge.axes; _i < _a.length; _i++) {
            var axis = _a[_i];
            _loop_1(axis);
        }
    };
    /**
     * Measure to calculate the axis size.
     *
     * @return {void}
     * @private
     */
    AxisLayoutPanel.prototype.measureAxisSize = function (gauge, rect) {
        var _this = this;
        var sum;
        this.computeSize(gauge.axes, rect);
        gauge.axes.map(function (axis, index) {
            sum = calculateSum(index, _this.farSizes.length - 1, _this.farSizes);
            axis.rect = new Rect(rect.x + sum, rect.y + sum, rect.width - (sum * 2), rect.height - (sum * 2));
        });
    };
    /**
     * Calculate the axis values of the circular gauge.
     *
     * @return {void}
     * @private
     */
    AxisLayoutPanel.prototype.calculateAxisValues = function (rect) {
        for (var _i = 0, _a = this.gauge.axes; _i < _a.length; _i++) {
            var axis = _a[_i];
            this.calculateVisibleRange(axis, rect);
            this.calculateVisibleLabels(axis);
        }
    };
    /**
     * Calculate the visible range of an axis.
     *
     * @param {Axis} axis - Specifies the axis.
     * @param {Rect} rect - Specifies the rect.
     * @returns {void}
     * @private
     */
    AxisLayoutPanel.prototype.calculateVisibleRange = function (axis, rect) {
        var interval = axis.majorTicks.interval;
        var minimumValue = Math.min(axis.minimum === null ? 0 : axis.minimum, axis.maximum !== null ? axis.maximum : 100);
        var maximumValue = Math.max(axis.minimum, axis.maximum === null ? 100 : axis.maximum);
        axis.pointers.map(function (pointer) {
            pointer.currentValue = pointer.value !== null ?
                pointer.value < minimumValue ? minimumValue : pointer.value > maximumValue ? maximumValue : pointer.value
                : minimumValue;
            minimumValue = axis.minimum === null ? Math.min(pointer.currentValue, minimumValue) : minimumValue;
            maximumValue = axis.maximum === null ? Math.max(pointer.currentValue, maximumValue) : maximumValue;
        });
        minimumValue = (minimumValue === maximumValue) ?
            (interval !== null ? minimumValue - interval : minimumValue - 1) : minimumValue;
        axis.visibleRange = { min: minimumValue, max: maximumValue, interval: interval };
        axis.visibleRange.interval = this.calculateNumericInterval(axis, rect);
    };
    /**
     * Calculate the numeric intervals of an axis range.
     *
     * @return {void}
     * @private
     */
    AxisLayoutPanel.prototype.calculateNumericInterval = function (axis, rect) {
        var allowComponentRender = ((!isNullOrUndefined(axis.minimum) && !isNullOrUndefined(axis.maximum) && axis.minimum !== axis.maximum) || (isNullOrUndefined(axis.minimum) || isNullOrUndefined(axis.maximum)));
        if (!allowComponentRender) {
            return 0;
        }
        else if (axis.majorTicks.interval !== null) {
            return axis.majorTicks.interval;
        }
        var totalAngle = axis.endAngle - axis.startAngle;
        totalAngle = totalAngle <= 0 ? (totalAngle + 360) : totalAngle;
        return this.calculateNiceInterval(axis.visibleRange.max, axis.visibleRange.min, axis.currentRadius ? axis.currentRadius : (rect.width / 2), totalAngle);
    };
    /**
     * Calculate the nice interval of an axis range.
     *
     * @return {void}
     * @private
     */
    AxisLayoutPanel.prototype.calculateNiceInterval = function (maxValue, minValue, radius, degree) {
        var delta = maxValue - minValue;
        var circumference = 2 * Math.PI * radius * (degree / 360);
        var desiredIntervalsCount = Math.max((circumference * ((0.533 * 3) / 100)), 1);
        var niceInterval = delta / desiredIntervalsCount;
        var minInterval = Math.pow(10, Math.floor(Math.log(niceInterval) / Math.log(10)));
        for (var _i = 0, _a = [10, 5, 2, 1]; _i < _a.length; _i++) {
            var interval = _a[_i];
            var currentInterval = minInterval * interval;
            if (desiredIntervalsCount < (delta / currentInterval)) {
                break;
            }
            niceInterval = currentInterval;
        }
        return niceInterval;
    };
    /**
     * Calculate the visible labels of an axis.
     *
     * @return {void}
     * @private
     */
    AxisLayoutPanel.prototype.calculateVisibleLabels = function (axis) {
        var style = axis.labelStyle;
        var customLabelFormat = style.format && style.format.match('{value}') !== null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var format = this.gauge.intl.getNumberFormat({
            format: getLabelFormat(style.format), useGrouping: this.gauge.useGroupingSeparator
        });
        var argsData;
        axis.visibleLabels = [];
        var roundValue;
        var interval = axis.visibleRange.interval;
        var max = axis.visibleRange.max;
        if ((isNullOrUndefined(axis.minimum) && isNullOrUndefined(axis.maximum)) || axis.minimum !== axis.maximum) {
            var _loop_2 = function (i) {
                roundValue = axis.roundingPlaces ? parseFloat(i.toFixed(axis.roundingPlaces)) : i;
                argsData = {
                    cancel: false, name: axisLabelRender, axis: axis,
                    text: customLabelFormat ? style.format.replace(new RegExp('{value}', 'g'), format(roundValue)) :
                        format(roundValue),
                    value: roundValue
                };
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                var axisLabelRenderSuccess = function (argsData) {
                    if (!argsData.cancel) {
                        axis.visibleLabels.push(new VisibleLabels(argsData.text, i));
                    }
                };
                axisLabelRenderSuccess.bind(this_2);
                this_2.gauge.trigger(axisLabelRender, argsData, axisLabelRenderSuccess);
            };
            var this_2 = this;
            for (var i = axis.visibleRange.min; (i <= max && interval); i += interval) {
                _loop_2(i);
            }
        }
        var lastLabel = axis.visibleLabels.length ? axis.visibleLabels[axis.visibleLabels.length - 1].value : null;
        var maxVal = axis.visibleRange.max;
        if (!isNullOrUndefined(lastLabel) && lastLabel !== maxVal && axis.showLastLabel === true) {
            argsData = {
                cancel: false, name: axisLabelRender, axis: axis,
                text: customLabelFormat ? style.format.replace(new RegExp('{value}', 'g'), format(maxVal)) :
                    format(maxVal),
                value: maxVal
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var axisLabelRenderSuccess = function (argsData) {
                if (!argsData.cancel) {
                    axis.visibleLabels.push(new VisibleLabels(argsData.text, maxVal));
                }
            };
            axisLabelRenderSuccess.bind(this);
            this.gauge.trigger(axisLabelRender, argsData, axisLabelRenderSuccess);
        }
        this.getMaxLabelWidth(this.gauge, axis);
    };
    /**
     * Measure the axes available size.
     *
     * @return {void}
     * @private
     */
    AxisLayoutPanel.prototype.computeSize = function (axes, rect) {
        var lineSize;
        var outerHeight;
        var innerHeight;
        var heightForCross;
        var axisPadding = 5;
        var majorTickOffset = 0;
        var minorTickOffset = 0;
        var labelOffset = 0;
        var labelPadding = 10;
        this.farSizes = [];
        this.calculateAxisValues(rect);
        for (var _i = 0, axes_1 = axes; _i < axes_1.length; _i++) {
            var axis = axes_1[_i];
            lineSize = (axis.lineStyle.width / 2);
            outerHeight = 0;
            innerHeight = 0;
            heightForCross = axis.majorTicks.position === 'Cross' ? axis.majorTicks.height / 2 : heightForCross;
            heightForCross = (axis.minorTicks.position === 'Cross' && heightForCross < axis.minorTicks.height / 2) ?
                axis.minorTicks.height / 2 : heightForCross;
            heightForCross = (axis.labelStyle.position === 'Cross' && heightForCross < axis.maxLabelSize.height / 2) ?
                axis.maxLabelSize.height / 2 : heightForCross;
            lineSize = lineSize < heightForCross ? heightForCross : lineSize;
            majorTickOffset = axis.majorTicks.offset;
            minorTickOffset = axis.minorTicks.offset;
            labelOffset = axis.labelStyle.offset;
            labelPadding = axis.labelStyle.shouldMaintainPadding ? 10 : 0;
            // Calculating the outer space of the axis
            outerHeight += !(axis.majorTicks.position === 'Outside' && axis.minorTicks.position === 'Outside' &&
                axis.labelStyle.position === 'Outside') ? axisPadding : 0;
            outerHeight += (axis.majorTicks.position === 'Outside' ? (axis.majorTicks.height + lineSize) : 0) +
                (axis.labelStyle.position === 'Outside' ? (axis.maxLabelSize.height + labelOffset + labelPadding) : 0) +
                ((axis.minorTicks.position === 'Outside' && !(axis.majorTicks.position === 'Outside')) ?
                    (axis.minorTicks.height + lineSize) : 0) + lineSize;
            outerHeight += (axis.majorTicks.position === 'Outside' && axis.minorTicks.position === 'Outside') ?
                Math.max(majorTickOffset, minorTickOffset) : (axis.majorTicks.position === 'Outside' ?
                majorTickOffset : axis.minorTicks.position === 'Outside' ? minorTickOffset : 0);
            // Calculating the inner space of the axis
            innerHeight += ((axis.majorTicks.position === 'Inside') ? (axis.majorTicks.height + lineSize) : 0) +
                ((axis.labelStyle.position === 'Inside') ? (axis.maxLabelSize.height + labelOffset + labelPadding) : 0) +
                ((axis.minorTicks.position === 'Inside' && axis.majorTicks.position === 'Outside') ?
                    (axis.minorTicks.height + lineSize) : 0) + lineSize;
            innerHeight += ((axis.majorTicks.position === 'Inside') && (axis.minorTicks.position === 'Inside')) ?
                Math.max(majorTickOffset, minorTickOffset) : ((axis.majorTicks.position === 'Inside') ?
                majorTickOffset : (axis.minorTicks.position === 'Inside') ? minorTickOffset : 0);
            if (this.farSizes[this.farSizes.length - 1]) {
                this.farSizes[this.farSizes.length - 1] += (innerHeight + outerHeight);
            }
            axis.nearSize = outerHeight - axisPadding;
            axis.farSize = innerHeight;
            outerHeight = (this.gauge.axes.length === (this.farSizes.length + 1)) ? 0 : outerHeight;
            this.farSizes.push(outerHeight);
        }
    };
    /**
     * To render the Axis element of the circular gauge.
     *
     * @return {void}
     * @private
     */
    AxisLayoutPanel.prototype.renderAxes = function (animate) {
        var _this = this;
        if (animate === void 0) { animate = true; }
        var gauge = this.gauge;
        var renderer = this.axisRenderer;
        var element;
        var axesElements = gauge.renderer.createGroup({
            'id': gauge.element.id + '_AxesCollection',
            'clip-path': 'url(#' + gauge.element.id + '_GaugeAreaClipRect_' + ')'
        });
        // To append the secondary element for annotation and tooltip
        gauge.element.appendChild(createElement('div', {
            id: gauge.element.id + '_Secondary_Element',
            styles: 'position: relative'
        }));
        gauge.axes.map(function (axis, index) {
            element = gauge.renderer.createGroup({
                id: gauge.element.id + '_Axis_Group_' + index
            });
            _this.gauge.allowComponentRender = ((!isNullOrUndefined(axis.minimum) && !isNullOrUndefined(axis.maximum) && axis.minimum !== axis.maximum) || (isNullOrUndefined(axis.minimum) || isNullOrUndefined(axis.maximum)));
            renderer.checkAngles(axis);
            renderer.drawAxisOuterLine(axis, index, element, gauge);
            renderer.drawAxisRange(axis, index, element);
            renderer.drawAxisLine(axis, index, element, gauge);
            renderer.drawMajorTickLines(axis, index, element, gauge);
            renderer.drawMinorTickLines(axis, index, element, gauge);
            renderer.drawAxisLabels(axis, index, element, gauge);
            _this.pointerRenderer.drawPointers(axis, index, element, gauge, animate);
            if (gauge.annotationsModule) {
                gauge.annotationsModule.renderAnnotation(axis, index);
            }
            axesElements.appendChild(element);
        });
        // For append clip rect for axes
        gauge.svgObject.appendChild(gauge.renderer.drawClipPath({
            'id': gauge.element.id + '_GaugeAreaClipRect_',
            'x': 0, 'y': 0,
            'width': gauge.availableSize.width,
            'height': gauge.availableSize.height,
            'fill': 'transparent', 'stroke': 'transparent'
        }));
        gauge.svgObject.appendChild(axesElements);
    };
    /**
     * Calculate maximum label width for the axis.
     *
     * @param {CircularGauge} gauge - Specifies the instance of the gauge.
     * @param {Axis} axis - Specifies the axis.
     * @returns {void}
     */
    AxisLayoutPanel.prototype.getMaxLabelWidth = function (gauge, axis) {
        axis.maxLabelSize = new Size(0, 0);
        for (var _i = 0, _a = axis.visibleLabels; _i < _a.length; _i++) {
            var label = _a[_i];
            label.size = measureText(label.text, axis.labelStyle.font);
            axis.maxLabelSize.width = label.size.width > axis.maxLabelSize.width ?
                label.size.width : axis.maxLabelSize.width;
            axis.maxLabelSize.height = label.size.height > axis.maxLabelSize.height ?
                label.size.height : axis.maxLabelSize.height;
        }
    };
    return AxisLayoutPanel;
}());
export { AxisLayoutPanel };
